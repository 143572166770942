import { Button } from 'components/ui';
import React, { FC, useState } from 'react';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

interface IFilterWrapper {
  children: React.ReactNode;
}

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${globalTheme.colors.white};
  padding: ${globalTheme.space[1]} ${globalTheme.space[2]};

  @media screen and (min-width: ${breakpoints.large}) {
    .smallScreen {
      display: none;
    }
  }

  @media screen and (max-width: ${breakpoints.large}) {
    .smallScreen {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .smallScreen-toggleFilters.active {
      color: ${globalTheme.colors.white};
      background-color: ${globalTheme.colors.black};
      box-shadow: none;
    }

    > .filters {
      &:not(.active) {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.2s linear, height 0.25s linear;
      }
      &.active {
        padding-top: ${globalTheme.space[1]};
        margin: ${globalTheme.space[1]};
        border-top: 1px solid ${globalTheme.colors.lightGray};
        height: max-content;
        opacity: 1;
        transition: height 0.25s linear, opacity 0.2s linear;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.small}) {
    top: ${globalTheme.space[0]};
    border-radius: ${globalTheme.space[0]};
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

export const FilterWrapper: FC<IFilterWrapper> = ({ children }) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.medium})`);

  const [toggleFilters, setToggleFilters] = useState(false);
  const handleToggleFilters = () => {
    setToggleFilters(!toggleFilters);
  };

  return (
    <Container>
      <div className='smallScreen'>
        <Button
          size='small'
          variant='outline'
          className={`smallScreen-toggleFilters ${
            toggleFilters ? 'active' : ''
          }`}
          onClick={() => handleToggleFilters()}
          fontSize={isMobile ? 'bookingMobile' : 'default'}
        >
          Filters
        </Button>
      </div>
      <Wrapper className={`filters ${toggleFilters ? 'active' : ''}`}>
        {children}
      </Wrapper>
    </Container>
  );
};

'use strict';

import React, { useEffect, useMemo, useState } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useRouter } from 'next/router';
import { useBookingsConfig } from 'hooks/use-bookings-config';
import { useBookingStock } from 'hooks/use-booking-stock';
import Link from 'next/link';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { Flex, ListCard, Loader, Text } from '../../components/ui';
import { StockBookRequiredMobile } from '../../components/stockbook-required/stockbook-required-mobile';
import { NextPage } from 'next';
import { withAuth } from '../../components/with-auth';
import { StockRequiredSummaryHeader } from 'components/stock-required-summary';
import { useBuyerOrg } from 'hooks/use-buyer-org';
import { ApiClient } from 'lib/api/client';
import { useQuery } from '@tanstack/react-query';
import { globalTheme } from 'styles/global-theme';
import { breakpoints } from 'styles/breakpoints';
import { ProductTypes } from '../../lib/api/generated';

const Container = styled(ListCard)`
  margin: 110px auto;
  display: flex;
  gap: 15px;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  max-width: 570px;
`;

const FieldSet = styled.fieldset`
  @media screen and (max-width: ${breakpoints.large}) {
    border: none;
    padding: 0;
    margin: 0;
    legend {
      display: none;
    }
  }
  @media screen and (min-width: ${breakpoints.small}) {
    border-radius: ${globalTheme.space[0]};
    border-color: ${globalTheme.colors.lightGray};
    legend {
      font-size: ${globalTheme.fontSizes[2]};
      font-weight: 600;
      padding: 0 8px;
      left: 10px;
      background-color: ${globalTheme.colors.lightGray};
      border-radius: ${globalTheme.space[0]};
    }
  }
`;

const StockBookRequiredSummary: NextPage = () => {
  const router = useRouter();
  const [filters, setFilters] = useState<{ type: string } | undefined>(
    { type: router?.query?.type as string } || undefined
  );
  const { data: config, isLoading: configLoading } = useBookingsConfig();

  const { data: org } = useBuyerOrg();

  const { isLoading, data: orgConfig } = useQuery(['reports'], () =>
    new ApiClient().organisationApi.orgConfigRetrieve({
      buyerOrgId: org?.buyer_org_id || '',
    })
  );

  const [categories, setCategories] = useState<{
    mainCategory?: string;
    subCategory?: string | string[];
  }>({
    mainCategory: undefined,
    subCategory: undefined,
  });

  const { data: stockData, isLoading: dataLoading } = useBookingStock({
    product: filters?.type,
  });

  const { show } = useIntercom();

  const configCategories = useMemo(() => {
    return config?.categories[filters?.type as string];
  }, [filters?.type, filters]);

  useEffect(() => {
    if (
      (router.query.type === undefined || router.query.type === '') &&
      config
    ) {
      const updatedParams = { type: config?.products?.[0]?.product_id };

      setFilters(updatedParams);
    }
  }, [router, config]);

  if (configLoading || isLoading) {
    return <Loader floating size={3} />;
  }

  if (!config || !orgConfig) {
    return <></>;
  }

  const hideMainCat = orgConfig?.data.reports?.homepage?.find(
    (ac) => ac.id === 'stockbook_summary'
  )?.['settings']['hide_main_cat'];

  return (
    <div>
      <Text fontSize={5} fontWeight='bold'>
        Stock Required Summary
      </Text>
      <FieldSet>
        <legend>Filters</legend>
        <StockRequiredSummaryHeader
          config={config}
          categories={categories}
          setCategories={setCategories}
          setProductType={setFilters}
          productType={filters?.type}
        />
      </FieldSet>
      <Flex direction='column' gap={1} padding={1}></Flex>
      {(configCategories && Object.keys(configCategories?.MAIN).length > 0) ||
      (configCategories && Object.keys(configCategories?.SUB).length) ? (
        <span>
          <StockBookRequiredMobile
            config={config}
            stockData={stockData}
            dataLoading={dataLoading}
            productType={filters?.type}
            allCategories={configCategories}
            categories={categories}
            hideMainCat={hideMainCat}
          />
        </span>
      ) : (
        <Container>
          <Text>
            This product type needs configuration from the Agora team. Please
            <Link href={'#'} onClick={show}>
              {' '}
              chat to us find out more.
            </Link>
          </Text>
        </Container>
      )}
    </div>
  );
};

export default withAuth(StockBookRequiredSummary);

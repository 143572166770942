import { useQuery } from '@tanstack/react-query';
import { ApiClient } from 'lib/api/client';
import { useStore } from 'lib/store';
import { QUERY_KEYS } from 'settings/config';
import { useApiReady } from './use-api-ready';

/** Get the selected buyer org */
export function useBookingStock({ product }: any) {
  const enabled = useApiReady();
  const activeBuyerOrgId = useStore((store) => store.activeBuyerOrgId);

  return useQuery(
    [QUERY_KEYS.bookingStock, product],
    () =>
      new ApiClient().OrganisationBookingStockApi.orgBookingStockList({
        product: product || '',
        buyerOrgId: activeBuyerOrgId || '',
      }),
    {
      select: (data) => data.data,
      enabled: enabled && !!activeBuyerOrgId && !!product,
    }
  );
}

import { useQuery } from '@tanstack/react-query';
import { ApiClient } from 'lib/api/client';
import { useStore } from 'lib/store';
import { QUERY_KEYS } from 'settings/config';
import { useApiReady } from './use-api-ready';

type Params = {
  orgId?: string;
};

export function useBookingsConfig({ orgId: overrideOrgId }: Params = {}) {
  const enabled = useApiReady();
  const activeBuyerOrgId = useStore((store) => store.activeBuyerOrgId);
  const orgId = overrideOrgId || activeBuyerOrgId;

  return useQuery(
    [QUERY_KEYS.bookingsConfig, orgId],
    () =>
      new ApiClient().bookingsApi.orgBookingConfigRetrieve({
        buyerOrgId: orgId || '',
      }),
    {
      select: (data) => data.data,
      enabled: enabled && !!orgId,
      keepPreviousData: true,
    }
  );
}

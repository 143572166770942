import { MainCategory, SubCategory } from 'components/category-selector';
import { FilterWrapper } from 'components/filter-wrapper';
import { Flex, RadioBox, Text } from 'components/ui';
import { BookingConfig, ProductEnum, ProductTypes } from 'lib/api/generated';
import { getMainCategory } from 'lib/helpers';
import React, { FC, SetStateAction, useEffect, useMemo } from 'react';
import { useMedia } from 'react-use';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from '../../styles/global-theme';

interface IStockRequiredSummaryHeader {
  config: BookingConfig;
  categories: {
    mainCategory?: string;
    subCategory?: string | string[];
  };
  setCategories: React.Dispatch<
    SetStateAction<{
      mainCategory?: string;
      subCategory?: string | string[];
    }>
  >;
  setProductType: React.Dispatch<SetStateAction<{ type: string } | undefined>>;
  productType: string | undefined;
}

export const StockRequiredSummaryHeader: FC<IStockRequiredSummaryHeader> = ({
  config,
  categories,
  setCategories,
  setProductType,
  productType,
}) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.medium})`);
  const mainCategory = useMemo(
    () => getMainCategory(config, productType),
    [productType]
  );

  useEffect(() => {
    if (
      mainCategory &&
      mainCategory.values &&
      mainCategory.values?.length === 1 &&
      !categories.mainCategory
    ) {
      setCategories?.({ mainCategory: mainCategory.values[0]?.key });
    }
  }, [mainCategory]);

  return (
    <FilterWrapper>
      <Flex
        gap={1}
        wrap='wrap'
        direction='column'
        alignItems={isMobile ? 'center' : ''}
      >
        <Flex
          gap={1}
          alignItems='center'
          direction={isMobile ? 'column' : 'row'}
          maxWidth={'max-content'}
        >
          <Text fontSize={3} fontWeight='medium' maxWidth={'max-content'}>
            Product type
          </Text>
          {config?.products.map((stockType: any, idx: any) => {
            const defaultValue = config?.products.find(
              (product) => product.product_id === productType
            );

            return (
              <RadioBox
                key={idx}
                onChange={() => {
                  setProductType({ type: stockType['product_id'] });
                  setCategories({
                    mainCategory: undefined,
                    subCategory: undefined,
                  });
                }}
                value={stockType['product_id']}
                label={stockType['name']}
                defaultChecked={
                  stockType.product_id === defaultValue?.product_id
                }
                checked={stockType.product_id === defaultValue?.product_id}
                defaultValue={defaultValue?.product_id}
                name={`radio_select-type`}
                height={globalTheme.space[3]}
                type='radio'
              />
            );
          })}
        </Flex>
        <MainCategory
          config={config}
          value={categories?.mainCategory}
          onChange={(el) => {
            {
              /** Assign the main category and reset the sub category */
            }
            setCategories({ mainCategory: el, subCategory: undefined });
          }}
          productType={productType}
        />
        <SubCategory
          config={config}
          type='checkbox'
          value={categories?.subCategory}
          mainCategory={categories?.mainCategory}
          onChange={(el) =>
            setCategories((prev) => ({ ...prev, subCategory: el }))
          }
          productType={productType}
        />
      </Flex>
    </FilterWrapper>
  );
};

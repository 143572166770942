import { Flex, RadioBox, Text } from 'components/ui';
import { BookingConfig } from 'lib/api/generated';
import { getSubCategory } from 'lib/helpers';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

interface ISubCategory {
  config?: BookingConfig;
  productType?: string;
  onChange?: (value: string | string[]) => void;
  showTitle?: boolean;
  mainCategory?: string;
  value?: string | string[];
  type?: 'radio' | 'checkbox';
}

export const SubCategory: FC<ISubCategory> = ({
  config,
  productType,
  onChange,
  showTitle = true,
  mainCategory,
  value,
  type,
}) => {
  const [values, setValues] = useState<string | string[] | undefined>(
    undefined
  );

  useEffect(() => {
    setValues(value);
  }, [value]);

  const isMobile = useMedia(`(max-width: ${breakpoints.medium})`);

  if (!config || !mainCategory) {
    return null;
  }
  const subCategory = getSubCategory(config, mainCategory, productType);

  if (!subCategory || !Array.isArray(subCategory.values)) {
    return null;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;

    if (type === 'checkbox') {
      // Handle multiple selections for checkboxes
      setValues((prev) => {
        const currentValues = Array.isArray(prev) ? prev : [];
        const newValues = currentValues.includes(selectedValue)
          ? currentValues.filter((val) => val !== selectedValue) // Remove if already selected
          : [...currentValues, selectedValue]; // Add if not selected
        onChange?.(newValues);
        return newValues;
      });
    } else {
      // Handle single selection for radio buttons
      setValues(selectedValue);
      onChange?.(selectedValue);
    }
  };

  return (
    <>
      {subCategory && subCategory?.values && !!subCategory?.values?.length && (
        <Flex
          gap={1}
          alignItems='center'
          direction={isMobile ? 'column' : 'row'}
          maxWidth={'max-content'}
        >
          {showTitle && (
            <Text
              fontWeight='medium'
              fontSize={isMobile ? 'bookingMobile' : 3}
              maxWidth={'max-content'}
            >
              {subCategory?.name}
            </Text>
          )}
          <Flex gap={1} wrap='wrap' justifyContent='center'>
            {subCategory.values.map((el, idx) => (
              <RadioBox
                key={idx}
                type={type === 'checkbox' ? 'checkbox' : 'radio'}
                value={el['key']}
                label={el['name']}
                name={`radio_select-sub-cat`}
                checked={
                  type === 'checkbox'
                    ? Array.isArray(values) && values.includes(el.key)
                    : el.key === values
                }
                height={globalTheme.space[3]}
                onChange={handleChange}
                bookingCell={isMobile}
              />
            ))}
          </Flex>
        </Flex>
      )}
    </>
  );
};
